import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';

import {
  Wrapper,
  BackButton,
  ButtonWrapper,
  ChevronLeft,
  TextWrapper,
} from './styled';

const PrivacyPolicyContent = () => {
  const {
    wpPage: { content },
  } = useStaticQuery(graphql`
    query PrivacyPolicy {
      wpPage(slug: { in: "privacy-policy" }) {
        content
      }
    }
  `);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <ButtonWrapper>
        <BackButton onClick={navigateBack}>
          <ChevronLeft />
          Back
        </BackButton>
      </ButtonWrapper>
      <TextWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </Wrapper>
  );
};

export default PrivacyPolicyContent;
