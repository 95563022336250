import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import backgroundImage from 'images/background/gradient-background.jpg';
import { ROUTES } from 'constants/common';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import PrivacyPolicyContent from 'components/PrivacyPolicy';

const PrivacyPolicy = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "privacy-policy" }) {
        ...MetaData
      }
    }
  `);

  wpPage.seo.opengraphUrl = ROUTES.privacyPolicy;

  return (
    <Layout backgroundImage={backgroundImage}>
      <SEO metadata={wpPage || {}} />
      <PrivacyPolicyContent />
    </Layout>
  );
};

export default PrivacyPolicy;
