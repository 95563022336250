import styled from 'styles/styled';

import { textStyles, titleStyles, subtitleStyles } from 'components/404/styled';

export const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 150px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
  align-items: flex-start;
  padding: 0 1.125rem;
  color: ${({ theme }) => theme.palette.lightDarkOpacity};

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
  }

  h1 {
    ${titleStyles};
    font-size: ${({ theme }) => theme.fontSize.title};
    margin-bottom: 20px;

    @media ${({ theme }) => theme.devices.medium} {
      font-size: ${({ theme }) => theme.fontSize.titleDesktop};
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    ${subtitleStyles};
    margin: 20px 0 15px;
    font-size: ${({ theme }) => theme.fontSize.subtitleMobile};

    @media ${({ theme }) => theme.devices.medium} {
      font-size: ${({ theme }) => theme.fontSize.title};
    }
  }

  p {
    ${textStyles};
    margin-bottom: 10px;
  }

  a {
    text-decoration: underline;
  }

  ul {
    padding-left: 20px;
    margin: 10px 0;
  }

  li {
    list-style-type: initial;
    ${textStyles};
    margin-bottom: 10px;
  }
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  color: ${({ theme }) => theme.palette.light};
  text-transform: uppercase;
  text-align: left;
  margin: 2.5rem 0 2.5rem 1.125rem;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
    transition: ${({ theme }) => theme.ui.transition('opacity')};
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-left: 0;
    padding-top: 0;
  }
`;

export const ChevronLeft = styled.div`
  width: 8px;
  height: 8px;
  margin: 4px 5px 4px 0;
  transform: rotate(45deg);
  border-left: 2px solid ${({ theme }) => theme.palette.light};
  border-bottom: 2px solid ${({ theme }) => theme.palette.light};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
`;
